<template>
  <app-header :show-style-guide-controls="!$route.path.includes('brand-assurance')" />
  <div class="mt-4">
    <router-view />
  </div>
</template>

<script>
import AppHeader from '@/components/app-header/AppHeader.vue';
export default {
    name: 'DefaultLayout',

    components: {
        AppHeader
    }
};
</script>
