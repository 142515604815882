<template>
  <header class="bg-primary-black w-full z-10">
    <img
      class="w-full cursor-pointer"
      :src="require('@/assets/images/asgardHeader.jpg')"
      @click="navigateToHome()"
    >
    <div
      class="flex px-1 py-2"
      :class="showStyleGuideControls ? 'justify-between' : 'justify-end'"
    >
      <style-guide-feature-controllers
        v-if="showStyleGuideControls"
        class="mr-4"
      />
      <navigation-controllers class="flex items-center justify-end" />
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';

export default {
    name: 'AppHeader',

    components: {
        StyleGuideFeatureControllers: defineAsyncComponent(() => import('./StyleGuideFeatureControllers.vue')),
        NavigationControllers: defineAsyncComponent(() => import('./NavigationControllers.vue'))
    },

    props: {
        showStyleGuideControls: {
            type: Boolean,
            default: true,
            description: 'Toggles style guides feature button controls of header'
        }
    },

    setup () {
        const router = useRouter();
        const { resetAssetsAndSearch } = useStyleGuideAssets();
        const navigateToHome = () => {
            resetAssetsAndSearch();
            router.currentRoute.value.path.includes('brand-assurance') ? router.push({ name: 'BAInbox' }) : router.push({ name: 'StyleGuide' });
        };
        const currentRoute = ref('');
        onMounted(() => {
            currentRoute.value = router.currentRoute.value.path;
        });

        return {
            navigateToHome,
            currentRoute
        };
    }
};
</script>
